import React from 'react'

import { PageLayout } from '../components'

const ContactTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <PageLayout lang={lang} switcher={page}>
      <>
        <div>ContactTemplate</div>
      </>
    </PageLayout>
  )
}

export default ContactTemplate
